import React, {useCallback, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {init, send} from "@emailjs/browser";

const ContactForm = () => {

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        init("CJd5J2hPUOUEbJ2eL");
    },[]);

    // for validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter your name"),
        company: Yup.string().required("Company is required"),
        message: Yup.string().required("Please, leave us a message."),
        email: Yup.string()
            .required("Email is required")
            .email("Entered value does not match email format"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    function onSubmit(data, e){
        setSubmitted(false)
        // display form data on success
        send("service_fww437f", "template_yarrlv5", data, "CJd5J2hPUOUEbJ2eL")
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        console.log("Message submited: " + JSON.stringify(data));
        setSubmitted(true);
        e.target.reset();
    };

    return (
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="messages"></div>
            <div className="row controls">
                <div className="col-md-6">
                    <div className="input-group-meta form-group mb-60">
                        <label>Your Name</label>
                        <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            {...register("name")}
                            className={`${errors.name ? "is-invalid" : ""}`}
                        />
                        {errors.name && (
                            <div className="invalid-feedback">
                                {errors.name?.message}
                            </div>
                        )}
                    </div>
                </div>
                {/* End .col */}

                <div className="col-md-6">
                    <div className="input-group-meta form-group mb-60">
                        <label>Company</label>
                        <input
                            type="text"
                            placeholder="Company Name"
                            name="company"
                            {...register("company")}
                            className={`${errors.company ? "is-invalid" : ""}`}
                        />
                        {errors.company && (
                            <div className="invalid-feedback">{errors.company?.message}</div>
                        )}
                    </div>
                </div>
                {/* End .col */}

                <div className="col-12">
                    <div className="input-group-meta form-group mb-60">
                        <label>Email Address</label>
                        <input
                            placeholder="Enter your email address"
                            name="email"
                            type="text"
                            {...register("email")}
                            className={` ${errors.email ? "is-invalid" : ""}`}
                        />
                        {errors.email && (
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        )}
                    </div>
                </div>
                {/* End .col */}

                <div className="col-12">
                    <div className="input-group-meta form-group lg mb-40">
                        <label>Message</label>
                        <textarea
                            placeholder="Message content"
                            name="sendMessage"
                            type="text"
                            {...register("message")}
                            className={`${errors.sendMessage ? "is-invalid" : ""}`}
                        ></textarea>
                        {errors.sendMessage && (
                            <div className="invalid-feedback">
                                {errors.sendMessage?.message}
                            </div>
                        )}
                    </div>
                </div>
                {/* End .col */}

                <div className="col-12">
                    <button type="submit" className="theme-btn-two">
                        Send Message
                    </button>
                    <br/>
                    {submitted && <p>Thanks! Your message has been sent, we'll reply as soon as we can!</p>}
                </div>
                {/* End .col */}
            </div>
        </form>
    );
};

export default ContactForm;
