import React, {useState} from "react";
import {Link} from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
    ProSidebar,
    SidebarHeader,
    Menu as PSMenu,
    MenuItem,
    SubMenu,
    SidebarContent,
} from "react-pro-sidebar";

const Menu = () => {
    return (
        <ul className="navbar-nav">
            <li className="nav-item active" style={{opacity: 0, pointerEvents: "none"}}>
                <Link to="/" className="nav-link">
                    Home
                </Link>
            </li>

            {/*<li className="nav-item">*/}
            {/*    <Link to="/about" className="nav-link">*/}
            {/*        About*/}
            {/*    </Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item dropdown">*/}
            {/*    <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">*/}
            {/*        Projects*/}
            {/*    </a>*/}
            {/*    <ul className="dropdown-menu">*/}
            {/*        <li>*/}
            {/*            <Link to="projects/listshare" className="dropdown-item">*/}
            {/*                Listshare*/}
            {/*            </Link>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <Link to="projects/simpletime" className="dropdown-item">*/}
            {/*                Simple Time*/}
            {/*            </Link>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</li>*/}

            {/*<li className="nav-item dropdown">*/}
            {/*    <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">*/}
            {/*        Services*/}
            {/*    </a>*/}
            {/*    <ul className="dropdown-menu">*/}
            {/*        <li>*/}
            {/*            <Link to="services/react-native" className="dropdown-item">*/}
            {/*                React Native Development*/}
            {/*            </Link>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <Link to="services/business-integration" className="dropdown-item">*/}
            {/*                Business Integrations*/}
            {/*            </Link>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</li>*/}

            {/*<li className="nav-item">*/}
            {/*    <Link to="/jobs" className="nav-link">*/}
            {/*        Jobs*/}
            {/*    </Link>*/}
            {/*</li>*/}

            <ul className="right-widget">
                <li className="d-sm-flex">
                    <ul className="user-login-button d-flex ">
                        <li>
                            <Link to="contact" className="signUp-action">
                                Talk to us!
                            </Link>
                        </li>
                    </ul>
                    {/* End ul */}
                </li>
            </ul>

        </ul>
    );
    // End navbar nav mega menu main
};

const MenuMobile = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <div className="mega-menu-wrapper">
            <div className="mob-header multi-mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                    <div className={click ? "active" : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
            {/* End Header */}

            <ProSidebar
                className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
            >
                <SidebarHeader>
                    <div className="logo position-static">
                        <a href="index.html">
                            <img src="/images/logo/deski_07.svg" alt="home-demo"/>
                        </a>
                    </div>
                    <div className="fix-icon text-dark" onClick={handleClick}>
                        <img src="images/icon/close-w.svg" alt="icon"/>
                    </div>
                    {/* Mobile Menu close icon */}

                    {/* End logo */}
                </SidebarHeader>
                <SidebarContent>
                    <PSMenu>

                        {/*<MenuItem>*/}
                        {/*    <Link to="/">Home</Link>*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem>*/}
                        {/*    <Link to="/about">About</Link>*/}
                        {/*</MenuItem>*/}

                        {/*<SubMenu title="Projects">*/}
                        {/*    <MenuItem>*/}
                        {/*        <Link to="/projects/listshare">Listshare</Link>*/}
                        {/*    </MenuItem>*/}
                        {/*</SubMenu>*/}

                        {/*<SubMenu title="Services" className="plus alt">*/}
                        {/*    <MenuItem>*/}
                        {/*        <Link to="/services/react-native">React Native Development</Link>*/}
                        {/*    </MenuItem>*/}
                        {/*    <MenuItem>*/}
                        {/*        <Link to="/services/businesss-integrations">Business Integrations</Link>*/}
                        {/*    </MenuItem>*/}
                        {/*</SubMenu>*/}

                        {/*<MenuItem>*/}
                        {/*    <Link to="/jobs">Jobs</Link>*/}
                        {/*</MenuItem>*/}

                        <MenuItem>
                            <Link to="/contact">Talk to us!</Link>
                        </MenuItem>

                    </PSMenu>
                </SidebarContent>
            </ProSidebar>
        </div>
    )
        ;
};

export {Menu, MenuMobile};
