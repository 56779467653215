import React from "react";
import '@dotlottie/player-component';

const Hero = () => {

    return (
        <div className="hero-banner-two">
            <div className="container">
                <div className="row align-items-start justify-content-between">
                    <div className="col-lg-6 order-lg-last">
                        <div className="illustration-holder">
                            <dotlottie-player
                                autoplay={true}
                                loop={true}
                            src="lottie/coffee-shop.lottie"
                            />
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 order-lg-first">
                        <div className="hero-text-wrapper md-mt-50">
                            <h1>
                <span>
                  Perfection
                  <img
                      src="images/shape/line-shape-1.svg"
                      alt="shape"
                      className="cs-screen"
                  />
                </span>
                                {' '}is&nbsp;many things done well.
                            </h1>
                            <p className="sub-text">
                                Creative mobile app studio & professional development consultants.
                            </p>


                            <ul className="list-item d-flex mt-20 d-xs-none d-sm-none d-md-flex">
                                <li>iOS & Android Apps</li>
                                <li>Business integrations</li>
                                <li>Expert consultancy</li>
                            </ul>
                        </div>
                        {/* /.hero-text-wrapper */}
                    </div>
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </div>
        // End .hero-banner-two
    );
};

export default Hero;
