import React from "react";
import {Helmet} from "react-helmet";
import Social from "../../components/social/Social";
import Header from "../components/Header";
import Hero from "../components/Hero";
import ServiceBlock from "../components/ServiceBlock";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Sugar & Coffee | Mobile app studio. Professional consultancy.</title>
            </Helmet>
            {/* End Page SEO Content */}

            {/* =============================================
		   Start Header
		   ============================================== */}
            <Header/>
            {/* End HeaderTwo Variation Three */}

            {/* =============================================
			Hero Banner Two
		  ============================================== */}
            <Hero/>
            {/* End HeroBannerTwo */}

            {/* =============================================
				Fancy Feature Four
		  ============================================== */}
            <div className="fancy-feature-four mt-20">
                <div className="bg-wrapper">
                    <img
                        src="images/shape/18.svg"
                        alt="shape"
                        className="shapes shape-right"
                    />
                    <img
                        src="images/shape/19.svg"
                        alt="shape"
                        className="shapes shape-left"
                    />
                    <div className="container">
                        <div className="title-style-two text-center mb-100 md-mb-50">
                            <div className="row">
                                <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                                    <p>What we do</p>
                                    <h2>
                                        A range of professional <span>development services{" "}
                                        <img src="images/shape/line-shape-2.svg" alt="shape"/>
                    </span>
                                    </h2>
                                </div>
                                {/* End .col */}
                            </div>
                        </div>
                        {/* End .title */}

                        <div className="inner-content">
                            <img
                                src="images/shape/20.svg"
                                alt="shape"
                                className="shapes shape-one"
                            />
                            <img
                                src="images/shape/21.svg"
                                alt="shape"
                                className="shapes shape-two"
                            />
                            <ServiceBlock/>
                        </div>
                        {/* End .inner-content */}
                    </div>
                </div>
            </div>
            {/* /.fancy-feature-four */}

            <div
                className="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
                id="features"
            >
                <div className="container">
                    <div className="block-style-twentyFive">
                        <div className="row align-items-center">
                            <div
                                className="col-xl-7 col-lg-6 col-md-10 m-auto"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                            >
                                <div className="screen-container">
                                    <img src={require('../assets/images/listshare-promo-1.png')} alt="screen"/>
                                </div>
                                {/*  /.screen-container */}
                            </div>
                            <div
                                className="col-xl-5 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration="1200"
                            >
                                <div className="text-wrapper pl-xl-5">
                                    <h6>
                                        iOS & Android <span>Shared List App</span>
                                    </h6>
                                    <h3 className="title">Listshare</h3>
                                    <p>
                                        Shared shopping and chore lists for your family or flatshare.
                                        <br/>
                                        Our multi platform shared list app Listshare is our first public app release and a growing success.
                                        We're always adding new features and making improvements so make sure you check it out on your mobile app store!
                                    </p>
                                    <br/>
                                    <a href="https://listshareapp.com" target="_BLANK">www.listshareapp.com</a>
                                </div>
                                {/* /.text-wrapper */}
                            </div>
                        </div>
                    </div>
                    {/* /.block-style-twentyFive */}
                </div>
            </div>

            {/*=====================================================
				Useable Tools
			===================================================== */}
            <div className="useable-tools-section-two bg-shape mb-200 md-mb-90 pt-200 mt-200">
                <div className="bg-wrapper">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <div className="container">
                        <div className="title-style-two text-center mb-70 md-mb-10">
                            <div className="row">
                                <div className="col-lg-10 col-md-11 m-auto">
                                    <p>Struggling with data?</p>
                                    <h2>
                                        We can automate painful processes
                                        <span>
                      <img src="images/shape/line-shape-2.svg" alt=""/>
                                             and streamline your workflows.
                    </span>

                                    </h2>
                                </div>
                            </div>
                        </div>
                        {/*  /.title-style-two */}

                        <div className="icon-wrapper">
                            <Social/>
                        </div>
                        {/* /.icon-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.bg-wrapper */}
            </div>
            {/* /.useable-tools-section-two */}

            {/* 	=====================================================
				Footer Style Two
			===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <Footer/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <div className="bottom-footer-content">
                            <div className="d-flex align-items-center justify-content-center text-center">
                                <p>
                                    Sugar & Coffee Limited. Registered Company UK/Wales #14610485
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default Home;
