import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {ReactComponent as Logo} from '../assets/svg/logo.svg'

const NotFound = () => {
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Not Found | Sugar & Coffee mobile app studio</title>
            </Helmet>
            {/* End Page SEO Content */}

            <div className="error-page d-lg-flex align-items-center">
                <div className="img-holder order-lg-last">
                    <img
                        src="images/media/404.svg"
                        alt="media"
                        className="w-100 illustration"
                    />
                    <img
                        src="images/media/404-q.svg"
                        alt="media"
                        className="shapes qus"
                    />
                </div>
                <div className="text-wrapper order-lg-first">
                    <div className="logo">
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <h1 className="font-slab">
                        Sorry, <br />
                        That Page Can’t <br />
                        be Found.
                    </h1>
                    <p className="font-rubik">
                        Taken a wrong turn? Found a broken link? Opps! Sorry about that..
                    </p>

                    <Link
                        to="/"
                        className="back-home font-rubik d-flex align-items-center justify-content-center"
                    >
                        <span>Back to Home</span>
                        <img src="images/icon/53.svg" alt="icon" />
                    </Link>
                </div>
                {/* /.text-wrapper */}
            </div>
            {/* /.error-page */}
        </div>
    );
};

export default NotFound;
