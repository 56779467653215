import React from "react";

const FeatureContent = [
    {
        icon: "20",
        meta: "Mobile App Development",
        subTitle: `Using React Native we can get your mobile app project to market quickly, backed by a staggering number of open source technologies.`,
        dataDelay: "0",
    },
    {
        icon: "21",
        meta: "Bespoke System Integration",
        subTitle: `Manually moving business data between various systems? Let us solve your bespoke integration problems.`,
        dataDelay: "100",
    },
    {
        icon: "22",
        meta: "Web & Digital Design",
        subTitle: `Our relationship with talented digital artists can help take your project from concept to full multiplatform design.`,
        dataDelay: "300",
    },
];

const ServiceBlock = () => {
    return (
        <div className="row justify-content-center">
            {FeatureContent.map((val, i) => (
                <div
                    className="col-lg-4 col-md-6"
                    key={i}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={val.dataDelay}
                >
                    <div className="block-style-five">
                        <div className="icon">
                            <img src={`images/icon/${val.icon}.svg`} alt="icon" />
                        </div>
                        <h6 className="title">
                            <span>{val.meta}</span>
                        </h6>
                        <p>{val.subTitle}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceBlock;
