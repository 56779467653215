import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Routes from "./app/routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-RCRBE1RPFW";
ReactGA.initialize(TRACKING_ID);

const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    window.addEventListener("load", AOS.refresh);

    return (
        <>
            <Helmet>
                <title>Sugar & Coffee | Great apps, mobile development consultancy</title>
                <meta property="og:site_name" content="Sugar & Coffee"/>
                <meta
                    property="og:url"
                    content="https://sugarandcoffee.co.uk"
                />
                <meta property="og:type" content="website"/>
                <meta
                    property="og:title"
                    content="Sugar & Coffee | Mobile app studio, react native consultants"
                />
                <meta
                    name="keywords"
                    content="mobile app studio, development consultants, react native, ios, android, cloud integrations"
                />
                <meta
                    name="description"
                    content="Creating great apps using modern technologies. Development consultancy and creative app studio."
                />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                <meta name="msapplication-TileColor" content="#da532c"/>
                <meta name="theme-color" content="#ffffff"/>

            </Helmet>
            {/* End Seo Helmt */}

            <ScrollToTop/>
            <Routes/>

        </>
    );
};

export default App;
