import React, { useState } from "react";
import { Link } from "react-router-dom";
import {ReactComponent as Logo} from '../assets/svg/logo.svg'
import {Menu, MenuMobile} from "./Menu";

const Header = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 68) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <div
                className={
                    navbar
                        ? "theme-main-menu sticky-menu theme-menu-two bg-none fixed"
                        : "theme-main-menu sticky-menu theme-menu-two bg-none"
                }
            >
                <div className="d-flex align-items-center justify-content-center">
                    <div className="logo">
                        <Link to="/">
                            <Logo/>
                        </Link>
                    </div>
                    {/* End Logo */}

                    <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
                        <div className="container nav-container">
                            <div
                                className="navbar-collapse collapse"
                                id="navbarSupportedContent"
                            >
                                <div className="d-lg-flex justify-content-between align-items-center">
                                    <Menu />
                                </div>
                            </div>
                        </div>
                    </nav>
                    {/* End nav */}
                </div>

                <MenuMobile />
                {/* 	End Mega Menu for Mobile */}
            </div>
            {/* /.theme-main-menu */}
        </>
    );
};

export default Header;
