import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
    {
        icon: "fa-facebook",
        link: "https://www.facebook.com/",
    },
    {
        icon: "fa-twitter",
        link: "https://twitter.com/",
    },
    {
        icon: "fa-linkedin",
        link: "https://www.linkedin.com/",
    },
];

const Footer = () => {
    return (
        <div className="row justify-content-between">
            <div
                className="col-lg-2 col-12 footer-about-widget"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <a href="index-customer-support.html" className="logo">
                    <img style={{width: '46px'}} src={require('../assets/images/footer-logo.png')} alt="brand" />
                </a>
            </div>
            {/* /.about-widget */}
            <div
                className="col-lg-2 col-md-3 col-sm-6 footer-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
            >
                <h5 className="footer-title">Our Apps</h5>
                <ul>
                    <li>
                        <Link to="#">Listshare</Link>
                    </li>
                </ul>
            </div>
            {/* /.footer-list */}
            <div
                className="col-lg-2 col-md-3 col-sm-6 footer-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
                <h5 className="footer-title">Services</h5>
                <ul>
                    <li>
                        <Link to="#">Mobile development</Link>
                    </li>
                    <li>
                        <Link to="#">Game development</Link>
                    </li>
                    <li>
                        <Link to="#">Backend systems</Link>
                    </li>
                    <li>
                        <Link to="#">Cloud apps</Link>
                    </li>
                    <li>
                        <Link to="#">Digital design</Link>
                    </li>
                </ul>
            </div>
            {/* /.footer-list */}
            <div
                className="col-lg-2 col-md-3 col-sm-6 footer-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
            >
                <h5 className="footer-title">About</h5>
                <ul>
                    {/*<li>*/}
                    {/*    <Link to="#">About us</Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to="/contact">Contact us</Link>
                    </li>
                </ul>
            </div>
            {/* /.footer-list */}
            <div
                className="col-lg-3 col-md-3 col-sm-6 address-list"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
                <h5 className="footer-title">Business</h5>
                <ul className="info">
                    <li>
                        <a href="mailto:hello@sugarandcoffee.co.uk">hello@sugarandcoffee.co.uk</a>
                    </li>
                    <li>
                        <a href="Tel: +447383375081" className="mobile-num">
                            +44 7383 375 081
                        </a>
                    </li>
                    <li>
                        <p>
                            Sugar & Coffee Limited <br/>
                            71-75 Shelton Street, <br/>
                            Covent Garden, <br/>
                            London, WC2H 9JQ
                        </p>
                    </li>
                </ul>
                <ul className="social-icon d-flex">
                    {socialContent.map((val, i) => (
                        <li key={i}>
                            <a href={val.link} target="_blank" rel="noreferrer">
                                <i className={`fa ${val.icon}`}></i>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            {/* /.footer-list */}
        </div>
        //.row
    );
};

export default Footer;
