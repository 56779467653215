import React from "react";
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const Contact = () => {

    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Contact us | Sugar & Coffee Mobile app studio</title>
            </Helmet>
            {/* End Page SEO Content */}

            {/* =============================================
		   Start Header
		   ============================================== */}
            <Header/>
            {/* End HeaderTwo Variation Three */}



            {/* =============================================
				Fancy Hero Four
			==============================================  */}

            <div className="fancy-hero-four space-fix">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="shapes shape-five"></div>
                <div className="shapes shape-six"></div>
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-11 col-md-10 m-auto">
                                <h6>Contact us</h6>
                                <h2>A project in mind, or just some questions?</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.bg-wrapper */}
            </div>
            {/* /.fancy-hero-four */}

            {/*  =============================================
				Contact Style Two
			==============================================  */}
            <div className="contact-style-two">
                <div className="container">
                    <div className="contact-info-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-sm-6 d-lg-flex">
                                <div className="address-info">
                                    <div className="icon d-flex align-items-end">
                                        <img src="images/icon/44.svg" alt="icon" />
                                    </div>
                                    <div className="title">Location</div>
                                    <p className="font-rubik" style={{fontSize: 18}}>
                                        Sugar & Coffee Limited <br/>
                                        71-75 Shelton Street, <br/>
                                        Covent Garden, <br/>
                                        London, WC2H 9JQ
                                    </p>
                                </div>{" "}
                                {/* /.address-info  */}
                            </div>
                            {/* End .col */}

                            <div className="col-lg-4 col-sm-6 d-lg-flex">
                                <div className="address-info text-sm">
                                    <div className="icon d-flex align-items-end">
                                        <img src="images/icon/45.svg" alt="icon" />
                                    </div>
                                    <div className="title">Contact</div>
                                    <p className="font-rubik" style={{fontSize: 18}}>
                                        hello@sugarandcoffee.co.uk <br />
                                        +44 7383 375 081 <br/>
                                        <span style={{fontSize: 14}}>UK Business Hours</span>
                                    </p>
                                </div>{" "}
                                {/* /.address-info  */}
                            </div>
                            {/* End .col */}

                            <div className="col-lg-4 col-sm-6 d-lg-flex">
                                <div className="address-info">
                                    <div className="icon d-flex align-items-end">
                                        <img src="images/icon/46.svg" alt="icon" />
                                    </div>
                                    <div className="title">Social</div>
                                    <p className="font-rubik">Find us on social media</p>
                                    <ul className="d-flex justify-content-center">
                                            <li >
                                                Coming soon
                                            </li>
                                    </ul>
                                </div>
                                {/* /.address-info  */}
                            </div>
                            {/* End .col */}
                        </div>
                        <img
                            src="images/shape/64.svg"
                            alt="shape"
                            className="shapes shape-one"
                        />
                        <img
                            src="images/shape/65.svg"
                            alt="shape"
                            className="shapes shape-two"
                        />
                    </div>
                    {/* /.contact-info-wrapper */}

                    <div className="form-style-classic mt-200 md-mt-80">
                        <ContactForm />
                    </div>
                </div>
            </div>
            {/* /.contact-style-two */}

            {/* 	=====================================================
				Footer Style Two
			===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <Footer/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <div className="bottom-footer-content">
                            <div className="d-flex align-items-center justify-content-center text-center">
                                <p>
                                    Sugar & Coffee Limited. Registered Company UK/Wales #14610485
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default Contact;
